<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="dialog = true" v-if="canSell && hasCashRegisterOpened">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva venta
                        </v-btn>
                        <SingleCalendar refElement="activeInicio" :input-date.sync="datesRange.start" title="Fecha inicio"/>
                        <SingleCalendar refElement="activeFin" :input-date.sync="datesRange.end" title="Fecha fin" :min-date="datesRange.start"/>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="6" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search"
                        label="Buscar ventas por folio de ticket" single-inline hide-details
                        @keydown.enter="searchVenta" class="mr-2 font-weight-bold">
                        </v-text-field>
                        <v-tooltip left color="success" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mt-4 ml-2" color="success" v-bind="attrs" v-on="on" dark @click="handleExcelExport">
                                    <v-icon dark left>fas fa-file-export</v-icon>
                                </v-btn>
                            </template>
                            <span>Reporte ventas dirias</span>
                        </v-tooltip>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <template  v-if="getCashRegisterOpenedByUser.length > 0">
                    <h6 class="text-h6 red--text text-left">Cajas abiertas, favor de cerrarlas</h6>
                    <v-alert dense type="error" outlined v-for="cash in getCashRegisterOpenedByUser" :key="cash.id">
                        El usuario {{ cash.user }}, tiene una caja abierta en la sucursal {{ cash.sucursal }} con fecha de apertura {{ cash.opened }}
                    </v-alert>
                </template>
                <v-card-text>
                    <v-data-table dense :headers="filteredHeaders" :items="sell" sort-by="idVenta" class="elevation-1" 
                    :server-items-length="totalElements" :items-per-page="itemsPerPage"
                    :page.sync="page" @page-count="totalPages = $event" 
                    hide-default-footer>
                        <template v-slot:[`item.actions`] ="{ item }">
                            <ActionsVenta :item="item" :cancelOut="cancelOut" :canCancel="canCancel" />
                        </template>
                        <template v-slot:[`item.tipoSalida`]="{ item }">
                            <v-chip class="white--text text--lighten-4 text-h7" :color="item.tipoSalida.color" small dense filled label> {{ item.tipoSalida.nombre}} </v-chip>
                        </template>
                        <template v-slot:[`item.ventaEstatus`]="{ item }">
                            <v-chip class="white--text text--lighten-4 text-h7" :color="( item.ventaEstatus !== 'Cancelada' && item.saldo > 0) ? 'purple' : item.colorEstatus"
                                small dense filled label v-if="item.ventaEstatus != null "> {{ (item.ventaEstatus !== 'Cancelada' && item.saldo > 0) ? 'Emitida' : item.ventaEstatus }}
                            </v-chip>
                        </template>
                        <template v-slot:[`item.tipoPago.formaPago`]= "{ item }">
                            <v-chip class="white--text text--lighten-4 text-h7" :color="(item.tipoPago.id == 1 || item.saldo === 0) ? 'success' : 'pink'"
                                small dense filled label> {{ item.tipoPago.formaPago }}
                            </v-chip>
                        </template>

                        <template v-slot:[`item.saldoNatural`]="{ item }">
                            <span class="text-h7 font-weight-bold" :class="item.saldo > 0 ? 'red--text' : 'green--text'"> {{ item.saldoNatural }} </span>
                        </template>

                        <template v-slot:[`item.eliminado`]="{ item }">
                            <v-chip :color="colorstatus(item.eliminado)" dark v-text="item.eliminado == 'true' ? 'Inactivo' : 'Activo'">
                            </v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <PaginationNumber :itemsPerPage.sync="itemsPerPage" :pageCount.sync="totalPages"
                    :totalElements.sync="totalElements" :page.sync="page" :initialize.sync="initialize" />
                </v-card-actions>
            </v-card>
        </v-col>
        <ModalNuevaVenta :dialog.sync="dialog" :initialize.sync="initialize" :handleModal="handleModal" v-if="dialog"
        :monedas.sync="monedas" :clientes.sync="clientes" :tipoSalida.sync="tipoSalida"
        :tipoPagos.sync="tipoPagos" :sucursales.sync="sucursales"/>
        <ExcelExportVenta :dialog.sync="dialogExcelExport" :handleModal="handleExcelExport"
        :tipo-salida.sync="tipoSalida" :sucursales.sync="sucursales" :is-admin="isAdmin"
        v-if="dialogExcelExport"/>
    </v-row>
</template>
<script>
import { getParamsPagination } from '@/utils/pagination';
import TipoMonedaDataService from '@/service/catalogo/tipoMoneda/tipoMonedaDataService';
import ClienteDataService from '@/service/cliente/clienteDataService';
import TipoSalidaDataService from '@/service/venta/TipoSalidaDataService';
import TipoPagoDataService from '@/service/catalogo/tipoPago/tipoPagoDataService';
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';

import VentaDataService from '@/service/venta/VentaDataService';
import ModalNuevaVenta from "./NuevaVenta.vue";
import ExcelExportVenta from './ExcelExportVenta.vue';
import PaginationNumber from '@/components/shared/PaginationNumber.vue';
import UsuarioDataService from '@/service/usuario/usuarioDataService';
import Columns from '@/components/shared/Columns.vue';
import CashRegisterDataService from '@/service/cashRegister/CashRegisterDataService';
import { getToday } from '@/utils/DateUtil';
import ViewFilesTable from '@/components/shared/ViewFilesTable.vue';
import ActionsVenta from './ActionsVenta.vue';
import SingleCalendar from '@/components/shared/SingleCalendar.vue';

export default {
    name: "ListaVenta",
    components: { ModalNuevaVenta, PaginationNumber, ExcelExportVenta, Columns, ViewFilesTable, SingleCalendar, ActionsVenta },
    created() {
        this.initialize();
        this.getMoneda();
        this.getAllCliente();
        this.getAllTipoSalida();
        this.getAllTipoPago();
        this.getAllSucursal();
        const userId = this.getUserId;
        this.findCashRegisterOpenedByUser(userId);
    },
    data() {
        return {
            lstCashRegister: [],
            monedas: [],
            clientes: [],
            tipoSalida: [],
            tipoPagos: [],
            sucursales: [],

            headers: [
                { text: "Detalle", value: "actions", sortable: false, groupable: false, show: true },
                { text: "ID", align: " d-none", sortable: false, value: "idVenta", visible: false, groupable: false, show: false },
                { text: 'Sucursal salida', value: 'sucursalEnvia.nombre', groupable: false, show: true },
                { text: 'Sucursal recibe', value: 'sucursalRecibe.nombre', groupable: false, show: true },
                { text: "Folio", value: "ticketFolio", groupable: false, show: true },
                { text: 'Fecha salida', value: 'registerDate', groupable: false, show: true },
                { text: 'Tipo salida', value: 'tipoSalida', groupable: false, show: true },
                { text: 'Estatus', value: 'ventaEstatus', groupable: false, show: true },
                { text: "Cliente", value: "cliente.nombreComercial", groupable: false, show: true },
                { text: "Forma Pago", value: "tipoPago.formaPago", groupable: false, show: true },
                { text: "Total", value: "totalNatural", groupable: false, show: true },
                { text: "Saldo", value: "saldoNatural", groupable: false, show: true },
                { text: "Abonado", value: "abonadoNatural", groupable: false, show: true },
            ],
            datesRange:{ start: getToday().toFormat('yyyy-MM-dd'), end: getToday().toFormat('yyyy-MM-dd') },
            search: "",
            page: 1,
            totalPages: 0,
            itemsPerPage: 25,
            totalElements: 0,
            sell: [],

            dialog: false,
            //Details sell
            dialogExcelExport: false,
        }
    },
    methods: {
        searchVenta() {
            this.page = 1;
            this.initialize({ page: this.page, itemsPerPage: this.itemsPerPage });
        },
        initialize( options = {} ) {
            const params = getParamsPagination({ options, search: this.search,
                params: {
                    startDate: this.datesRange.start, endDate: this.datesRange.end,
                }
             });
            this.itemsPerPage = options.itemsPerPage;
            VentaDataService.table(params).then( resp => {
                const { rows, totalElements, totalPages, currentPage } = resp.data;
                this.sell = rows;
                this.totalElements = totalElements;
                this.page = currentPage + 1 ;
                this.totalPages = totalPages;
            }).catch( err => {
                this.$swal.fire({ icon: 'error', html: 'Error al obtener los datos', text: err.response.data, showConfirmButton: false, timer: 3500 });
            });
        },
        handleExcelExport(){
            this.dialogExcelExport = !this.dialogExcelExport
        },
        cancelarVenta( item ){
            this.$swal.fire({
                title: '¿Está seguro de cancelar la venta?',
                text: "La venta será cancelada y no se podrá recuperar",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, cancelarla!',
                cancelButtonText: 'No, cancelar!'
            }).then( result => {
                if (result.value) {
                    VentaDataService.cancelarVenta(item.id).then(resp => {
                        if( resp.status == 200 ){
                            this.$swal.fire({ icon: 'success', title: 'Venta cancelada', text: 'La venta ha sido cancelada', showConfirmButton: false, timer: 3500 });
                            this.initialize();
                        }
                    }).catch( err => {
                        this.$swal.fire({ icon: 'error', title: 'Error al cancelar la venta', text: err.reponse.data, showConfirmButton: false, timer: 3500 });
                    })
                }
            })
        },
        colorstatus(status) { return (!status) ? "green" : "red"; },
        handleModal() { this.dialog = !this.dialog; },
        // LOAD CATALOGS
        async getMoneda(){ this.monedas = await TipoMonedaDataService.getAllTipoMoneda().then( resp => resp.data ) || [] },
        async getAllCliente(){ this.clientes = await ClienteDataService.getAll().then( resp => resp.data ) || [] },
        async getAllTipoSalida(){ this.tipoSalida = await TipoSalidaDataService.getAllTipoSalida().then( resp => resp.data ) || [] },
        async getAllTipoPago(){ this.tipoPagos = await TipoPagoDataService.allTipoPago().then( rsp => rsp.data ) || []},
        async getAllSucursal(){ this.sucursales = await SucursalDataService.lstSucursal().then( resp => resp.data ) || [] },

        findCashRegisterOpenedByUser(userId) {
            CashRegisterDataService.findCashRegisterOpenedByUser(userId).then(response => {
                this.lstCashRegister = response.data;
            })
        },
        cancelOut(item){
            if(!this.canCancel){
                this.$swal.fire({ icon: 'error', html: 'No tienes permiso para cancelar ventas', showConfirmButton: false, timer: 3500 });
                return
            }
            this.$swal.fire({
                title: '¿Está seguro de cancelar la venta?',
                text: "La venta sera cancelada y no se podra revertir la accion",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, cancelarla!',
                cancelButtonText: 'No, cancelar!'
            }).then( result => {
                if (result.value) {
                    VentaDataService.cancel(item.id).then(resp => {
                        if( resp.status == 200 ){
                            this.$swal.fire({ icon: 'success', title: 'Venta cancelada', text: resp.data, showConfirmButton: false, timer: 5000 });
                            this.initialize();
                        }
                    }).catch( err => {
                        this.$swal.fire({ icon: 'error', title: 'Error al cancelar la Venta', text: err.response.data, showConfirmButton: false, timer: 3500 });
                    })
                }
            }).catch(err => {
                this.$swal.fire({ icon: 'error', html: 'Error al cancelar la venta' + err, showConfirmButton: false, timer: 3500 });
            })
        }
    },
    computed:{
        canSell(){ return UsuarioDataService.getUserRoles().includes('ROLE_VENDEDOR') || this.isAdmin; },
        isAdmin(){ return UsuarioDataService.getUserRoles().includes('ROLE_ADMIN'); },
        roles(){ return UsuarioDataService.getUserRoles(); },
        canCancel(){
            const rolesAllowed = ["ROLE_ADMINISTRATOR_1", "ROLE_ADMINISTRATOR_2", "ROLE_ADMIN", "ROLE_COBRANZA"]
            return rolesAllowed.includes(this.roles[0]);
        },
        filteredHeaders(){ return this.headers.filter( h => h.show ); },
        // To validate if the user has cash register opened at least one.
        getUserId() { return UsuarioDataService.getCurrentUser().id; },
        getSucursalId() { return SucursalDataService.getCurrentSucursalId(); },
        hasCashRegisterOpened() {
            if(this.lstCashRegister.length === 0) return false;
            const today = getToday().toFormat('dd/MM/yyyy');
            return this.lstCashRegister.filter(cashRegister => cashRegister.status === 'OPENED' && cashRegister.opened === today).length > 0;
        },
        getCashRegisterOpenedByUser() {
            if(this.lstCashRegister.length === 0) return [];
            const today = getToday().toFormat('dd/MM/yyyy');
            return this.lstCashRegister.filter(cashRegister => cashRegister.status === 'OPENED' && cashRegister.opened !== today);
        }
    },
    watch: {
        'datesRange.start': function( val, oldVal ) {
            if (val !== oldVal && this.datesRange.end != null && this.datesRange.end != '') {
                this.initialize(this.options);
            }
        },
    }
};
</script>
