<template>
    <div>
        <v-btn icon small color="primary p-2" @click.prevent="detalleVenta(item)">
            <v-icon>fa-solid fa-file-invoice-dollar</v-icon>
        </v-btn>
        <v-btn icon small color="pink p-2" @click.prevent="showEvidencesMerma(item)" v-if="item.tipoSalida.id == 2">
            <v-icon>fas fa-images</v-icon>
        </v-btn>
        <v-btn icon small color="red p-2" @click.prevent="cancelOut(item)"
            v-if="item.tipoSalida.id == 1 && item.ventaEstatus != 'Cancelada' && canCancel">
            <v-icon>fas fa-ban</v-icon>
        </v-btn>

        <DetalleSale :detalleOpen.sync="openDialogSale" :detailsVenta.sync="saleDetail" :handleModal="handleDialogSale" v-if="openDialogSale"/>
        <ViewFilesTable :lstFiles="lstEvidences" :dialog="dialogEvidences" title-files="Ficheros y evidencias de mermas"
            :handleDialogFiles="handleDialogFiles"  v-if="dialogEvidences">
        </ViewFilesTable>
    </div>
</template>
<script>
import ViewFilesTable from '@/components/shared/ViewFilesTable.vue';
import DetalleSale from './DetalleSale.vue';
import VentaEvidenceFileService from '@/service/venta/evidences/VentaEvidenceFileService';

export default {
    name: 'ActionsVenta',
    props: {
        item: { type: Object, required: true },
        cancelOut: { type: Function },
        canCancel: { type: Boolean }
    },
    components: { DetalleSale, ViewFilesTable},
    data() {
        return {
            openDialogSale: false,
            saleDetail: {},
            // to evidences
            lstEvidences: [],
            dialogEvidences: false
        }
    },
    methods: {
        detalleVenta( item ){
            this.saleDetail = item;
            this.openDialogSale = true;
        },
        showEvidencesMerma(item){
            this.dialogEvidences = true;
            this.getEvidencesMerma(item);
        },
        getEvidencesMerma({ id: ventaId }) {
            let params = { page: 0, size: 25 };
            this.lstEvidences = [];
            VentaEvidenceFileService.table( params, ventaId ).then( response => {
                let evidences = response.data.rows.map(evidence => {
                    return {
                        ...evidence,
                        typeFile: evidence.type.startsWith('image') ? 'image' : 'pdf',
                        url: evidence.name != null ? `${this.$store.state.auth.rutaFichero}ventaEvidences/${evidence.name}` : Camera
                    };
                });
                this.lstEvidences = evidences;
            }).catch(err => {
                this.lstEvidences = [];
                this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al cargar las evidencias' + err.response.data, showConfirmButton: false, timer: 3500 });
            })
        },
        handleDialogSale() { this.openDialogSale = !this.openDialogSale; },
        handleDialogFiles() { this.dialogEvidences = !this.dialogEvidences; },
    }
}
</script>