import { http, authHeader } from '@/http-commons';

class EntradaDataService {
    create( entrada ){
        return http.post('api/v1/entrada/store', entrada , { headers : authHeader() });
    }

    show( id ){
        return http.get(`api/v1/entrada/show/${id}`, { headers : authHeader() });
    }

    update( entrada ){
        return http.put(`api/v1/entrada/update`, entrada, { headers : authHeader() });
    }

    delete ( id ){
        return http.delete(`api/v1/entrada/delete/${id}`, {headers : authHeader() });
    }
    
    getEntradaTable( params ){
        return http.get('api/v1/entrada/listaEntrada', { params, headers : authHeader() });
    }
    findByCreditProvider(providerId){
        return http.get(`api/v1/entrada/findByCreditProvider/${providerId}`, { headers: authHeader() });
    }

}
export default new EntradaDataService();